jQuery(window).load(function() {
	jQuery(".bxslider").bxSlider({
		pager: false,
        auto: (jQuery(".bxslider").children().length < 2) ? false : true,
        touchEnabled:(jQuery(".bxslider").children().length < 2) ? false : true,
		controls:false,
        adaptiveHeight:true,
        keyboardEnabled: true,
        pause: 6000,
    });

    jQuery(".donor-caresoul").owlCarousel({
        navigation: true,
        autoPlay: true,
        autoWidth:true,
        loop:true,
        items:5,
        pagination: false,
        margin:10,
    });

    jQuery(".searchbox").click(function(){
       jQuery(".search-form").toggle();
    });

    jQuery(".mobile-menu a").on("click touchstart", function (event) {
		event.preventDefault();
		event.stopPropagation();
		jQuery("body").toggleClass("open-menu");
	});
   jQuery(".site-header .header-right").on("click touchstart", function (event) {
		event.stopPropagation();
	});

    jQuery(document).on("click", function () {
        jQuery("body").removeClass("open-menu");

    });

    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
	  });

    jQuery(".navigation ul li.menu-item-has-children").prepend("<span class='plus'></span>");

});


jQuery(window).load(function () {
	global();
});

jQuery(window).resize(function () {
	global();
});

function global(){
	equalheight(".distribute-section .col-md-6");
	equalheight(".news-list");
}

equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).height("auto");
            topPostion = jQueryel.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.height();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};

var CONTENT = document.querySelector(".tingle__content");

// init tingle
if (CONTENT) {
    var CLASS = CONTENT.querySelector(".tingle__image") ? ["tingle-modal--image"] : ["tingle-modal--text"];

    var MODAL = new tingle.modal({
        cssClass: CLASS,
        onClose: function () {
            sessionStorage.aaifp_modal_viewed = true;
        },
    });

    MODAL.setContent(CONTENT.outerHTML);

    /**
     * If a link is clicked within the modal, mark the modal as closed
     */
    var LINKS = document.querySelectorAll(".tingle__content a");

    if (LINKS.length > 0) {
        LINKS.forEach(function (link) {
            link.addEventListener("click", function () {
                sessionStorage.aaifp_modal_viewed = true;
            }, { passive: true });
        });
    }

    if (!sessionStorage.aaifp_modal_viewed) {
        MODAL.open();
    }
}
